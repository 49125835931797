
.announcement
  display: grid
  grid-template-columns: 1fr auto
  border-radius: var(--border-radius-container)
  background-color: var(--bgc-content-container)
  min-height calc(8 * var(--layout-spacing-unit))

  &--info
    background-color: var(--bgc-announcements-info)

  &--danger
    background-color: var(--bgc-announcements-danger)

  &--success
    background-color: var(--bgc-announcements-success)

  &--warn
    background-color: var(--bgc-announcements-warn)

.announcement__content
  text-align: center
  flex: 1 1 auto

.announcement__title
  margin: 0
  padding: var(--layout-spacing-unit)
  display: flex
  justify-content: center
  align-items: center

.announcement__message
  margin: 0
  padding: var(--layout-spacing-unit)
  text-align: center

.announcement__closeWrapper
  padding: var(--layout-spacing-unit)
  display: flex
  align-items: center

.announcement--sticky .announcement__closeButton
  visibility: hidden

.announcement.announcement--withMessage
  .announcement__title,
  .announcement__closeWrapper
    border-bottom: 1px solid var(--bgc-content-body)
